.input.input {
  width: 100%;
  font-weight: 400;
  font-family: 'Inter';
  border-color: var(--chakra-colors-_primary-100);
  position: relative;
  z-index: 0;
  padding: unset;
  padding-left: var(--chakra-space-20);
  box-sizing: border-box;
}

.input.input.sm {
  font-size: 1rem;
  height: 2.5rem;
  border-radius: var(--chakra-radii-md);
}

.input.input.md {
  font-size: 1.125rem;
  height: 3rem;
  border-radius: var(--chakra-radii-md);
}

.input.input.lg {
  font-size: 1.125rem;
  height: 4rem;
  border-radius: var(--chakra-radii-lg);
}

.input.inside {
  padding-top: var(--chakra-space-4);
}

.input.input.clearable {
  padding-right: var(--chakra-space-16);
}

.input.input::placeholder {
  color: var(--chakra-colors-_primary-500);
}

.input.inside::placeholder {
  color: transparent;
}

.input.inside.has-value::placeholder,
.input.inside:focus::placeholder {
  color: var(--chakra-colors-_primary-500);
}

.input.input:hover {
  border-color: var(--chakra-colors-_primary-900);
}

.input.input:focus {
  border: 1px solid var(--chakra-colors-accent-500);
  box-shadow: 0 0 0 1px var(--chakra-colors-accent-500);
  outline: 4px solid var(--chakra-colors-accent-100);
  outline-offset: 1px;
}

.input.input[aria-invalid='true'],
.input.input:hover[aria-invalid='true'],
.input.input:focus[aria-invalid='true'] {
  border: 1px solid var(--chakra-colors-border-error);
}

.input.input[aria-disabled='true'],
.input.input:hover[aria-disabled='true'],
.input.input:focus[aria-disabled='true'] {
  box-shadow: none;
  outline: none;
  border-color: var(--chakra-colors-_primary-50);
  background-color: var(--chakra-colors-_primary-50);
  color: var(--chakra-colors-_primary-300);
}

.button {
  z-index: 0;
  left: var(--chakra-space-3);
}
